.input-md .css-1480iag-MuiInputBase-root-MuiInput-root:before,
.input-md .css-1480iag-MuiInputBase-root-MuiInput-root:hover,
.input-md .css-1480iag-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid #EE7330;
 }

 .input-md .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: rgb(105, 105, 105) !important;
}

.input-md .css-1x51dt5-MuiInputBase-input-MuiInput-input:focus,
.input-md .css-1x51dt5-MuiInputBase-input-MuiInput-input:hover
 {
  outline-color: transparent;
  border-bottom: 2px solid #EE7330 !important;
}

.input-md {
  width: 100%;
}

.login-input {
  max-width: 300px;
}