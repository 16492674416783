@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

@import url("./login.css");
@import url("./buttons.css");
@import url("./inputs.css");
@import url("./home.css");
@import url("./grid.css");

html, body, #root {
  min-height: 100vh;
  min-width: 100%;
}