.button-md .css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: #EE7330;
}

.comp-btn {
  border: 2px solid #EE7330 !important;
  color: #EE7330 !important;
  margin-right: 30px !important;
}

.card-info {
  display: flex;
  justify-content: space-between;
}

.card-info button {
  height: 50%;
  align-self: center;
}