.card-home .card-paper {
  box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 17%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin-top: 60px;
  height: 180px;
  width: 300px;
  padding: 25px 30px;
}

.card-home .card-paper-no-margin {
  box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 17%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin-top: 0px;
  height: 180px;
  width: 300px;
  padding: 25px 30px;
}

.home-comp h2 {
  margin-bottom: 0;
}

.card-paper img {
  width: 50px;
}

.card-paper-no-margin img {
  width: 50px;
}

.link-home {
  float: right;
  color: #EE7330 !important;
  text-decoration-color: #EE7330 !important;
  font-weight: 600;
}